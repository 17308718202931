import { ref } from "vue";
import { PayoutCodeOverviewData } from "@/views/Payment/Mobile/PendingApproval/type";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ApproveData } from '@/views/Payment/types'
import { message } from 'ant-design-vue';
import * as API from "@/API/payment";

export const usePayoutCodeAFCLC = () => {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();

  const data = ref<PayoutCodeOverviewData>({
    id: 0,
    payoutRound: "",
    payoutCode: "",
    programNo: 0,
    vinNo: 0,
    payoutAmountWVat: "",
    programYear: null,
  });
  const programYear = ref<string[]>();

  API.getFinanceDetail(route.query.id as string).then((res) => {
    data.value = res
    // data.value.payoutAmountWVat = route.query.wvat as string
    // data.value.payoutAmountWVoat = route.query.wvoat as string
    data.value.payoutAmountWVat = res.payoutAmountWVat
    data.value.payoutAmountWVoat = res.payoutAmountWVoat
  })
  API.getPendingDetailAL(route.query.payoutCode).then((res) => {
    programYear.value = res;
  });

  const total = ref<string>("0");
  if (route.query.total) {
    total.value = route.query.total + "";
  } else {
    const params = {
      pageNumber: 1,
      pageSize: 10000,
      userId: store.state.user.usrid,
      typeList: []
    }
    API.getDealerPendingList(params).then((res) => {
        console.log('获取审批菜单下主页面数据-Dealer：',res)
        total.value = res.content.length
        API.getFinancePendingList(params).then((res) => {
          console.log('获取审批菜单下主页面数据-MBAFCandMBLC：',res)
          total.value += res.content.length;
      });
    });
  }

  const actionType = ref<string>("approve");
  const approvalModalVisible = ref<boolean>(false);
  const modalVisible = ref<boolean>(false);
  const modalMessage = ref<string>("");
  const showMessage = (message: string) => {
    modalMessage.value = message;
    modalVisible.value = true;
  };
  const toHomePage = () => {
    router.push({ path: "/paymentMobile/pendingApproval" });
  };
  const handleCommentsConfirm = (comment: string) => {
    const closeModal = () => {
      approvalModalVisible.value = false;
    };

    const params = [{
      rejectComment: '',
      approveComment: "",
      delegationUserId: route.query.delegationUserId,
      id: data.value?.id,
      requestUserId: route.query.requestUserId
    }];

    if (actionType.value === "reject") {
      params[0].rejectComment = comment;
      API.fetchPaymentReject(params).then((res) => {
        if (res.code == "0") {
          closeModal();
          showMessage("Successfully Reject！");
          setTimeout(() => {
            toHomePage();
          }, 2000)
        } else {
          closeModal();
          showMessage(res.message);
        }
      });
    } else if (actionType.value === "approve") {
      params[0].approveComment = comment;
      API.fetchPaymentApprove(params).then((res) => {
        if (res.code == "0") {
          closeModal();
          showMessage("Successfully Approve！");
          setTimeout(() => {
            toHomePage();
          }, 2000)
        } else {
          closeModal();
          showMessage(res.message);
        }
      });
    }
  };

  // 审批相关
  const approveList = ref<ApproveData[]>();
  approveList.value = [];
  const id = route.query.id as string
  const getApprovalPath = () => {
    const params = {
      approvalType: "PayoutApproval",
      payoutCodeId: id,
      payoutType: "FL",
    };
    API.getApprovalPackageByCreated(params).then((res: any) => {
      if (res.code === "0") {
        approveList.value = res.data.map((item: any) => item.nodeList)
      } else {
        message.error(res.message)
      }
    });
  };
  getApprovalPath()

  return {
    total,
    data,
    programYear,
    actionType,
    approvalModalVisible,
    modalVisible,
    modalMessage,
    handleCommentsConfirm,
    toHomePage,
    approveList
  };
};
